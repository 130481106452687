import { useFetcher } from '@remix-run/react';
import type { Hit } from 'instantsearch.js';
import { useEffect } from 'react';
import type { CreditCard as TCreditCard } from '@cardo/types';
import CreditCard from '~/components/blocks/CreditCard';

export default function CreditCardHit({ hit }: { hit: Hit }) {
  const cardFetcher = useFetcher<{ creditCard: TCreditCard }>();
  const card = cardFetcher.data?.creditCard;

  const loading = cardFetcher.state === 'loading';

  useEffect(() => {
    if (cardFetcher.state === 'idle' && !cardFetcher.data && hit.id) {
      cardFetcher.load(
        `/resource/credit-cards/${hit.id}?populate=blog&populate=calculator&populate=editorial`
      );
    }
  }, [cardFetcher, hit.id]);

  if (card && !loading) {
    return (
      <CreditCard
        id={card.id}
        creditCard={{ data: card }}
        __component="client.credit-card"
      />
    );
  }

  return (
    <div className="mx-auto flex h-[528px] w-full animate-pulse flex-col space-y-8 rounded-md border bg-gray-200 p-8 shadow-md md:h-[320px]"></div>
  );
}
