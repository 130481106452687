import { useInstantSearch } from 'react-instantsearch';

type EmptyQueryBoundaryProps = {
  children: React.ReactNode;
  fallback: React.ReactNode;
};

export default function EmptyQueryBoundary({
  children,
  fallback,
}: EmptyQueryBoundaryProps) {
  const { indexUiState, error } = useInstantSearch();

  if (!indexUiState.query || error) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
}
