import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { SearchBox, useInstantSearch } from 'react-instantsearch';
import { useTimeoutFn } from 'react-use';
import type { BrandedPage } from '@cardo/types';
import { topPicksCategories } from '@cardo/lib';
import { envPrefixed } from '~/lib/utils';
import { Button, AwardRibbon, PiggyBank, Library } from '@cardo/ui';
import PageNavLink from './PageNavLink';
import SubNavItem from './SubNavItem';

type TSubNavItem = {
  label: string;
  onClick: () => void;
};

type BrandedPageNavProps = {
  brandedPages: BrandedPage[];
  subNavItems?: TSubNavItem[];
  bankingPageEnabled?: boolean;
  businessBankingPageEnabled?: boolean;
};

export default function BrandedPageNav({
  brandedPages,
  subNavItems,
  bankingPageEnabled = false,
  businessBankingPageEnabled = false,
}: BrandedPageNavProps) {
  const [subNavOpen, setSubNavOpen] = useState(false);

  const { results, uiState, setUiState } = useInstantSearch();
  const query = uiState[envPrefixed('api::credit-card.credit-card')]?.query;

  useEffect(() => {
    if (
      subNavItems &&
      subNavItems?.length > 0 &&
      results &&
      results.nbHits > 0
    ) {
      if (query && query !== '') {
        setSubNavOpen(false);
      }
    }
  }, [results, query, subNavItems]);

  useTimeoutFn(() => {
    if (subNavItems && subNavItems?.length > 0) setSubNavOpen(true);
  }, 250);

  const topPicksPage = brandedPages.find(
    (page) => page.attributes.pageType === 'top-picks'
  );
  const topPicksCategoryPages = brandedPages.filter(
    (page) => page.attributes.pageType === 'top-picks-category'
  );

  function handleClearSearchResults() {
    setUiState({
      [envPrefixed('api::credit-card.credit-card')]: {
        query: '',
      },
    });
    if (subNavItems && subNavItems?.length > 0) {
      setSubNavOpen(true);
    }
  }

  return (
    <>
      <div className="bg-theme-blue-darkest mx-auto flex h-full w-full max-w-[1200px] flex-col justify-between rounded-lg p-8 drop-shadow-xl">
        <div className="flex flex-col gap-4 sm:gap-8">
          <div className="flex flex-col flex-wrap gap-4 sm:flex-row sm:gap-x-6 sm:gap-y-3">
            {topPicksPage && (
              <PageNavLink
                to={`/i/${topPicksPage?.attributes.slug}`}
                icon={<AwardRibbon />}
                end
              >
                Top Cards
              </PageNavLink>
            )}
            {topPicksCategories.map((category) => {
              const categoryPage = topPicksCategoryPages.find(
                (page) => page.attributes.pageSubType === category.value
              );

              if (!categoryPage) return null;

              return (
                <PageNavLink
                  to={`/i/${categoryPage.attributes.slug}`}
                  key={categoryPage.attributes.slug}
                  icon={category.icon}
                >
                  {
                    topPicksCategories.find(
                      ({ value }) =>
                        value === categoryPage.attributes.pageSubType
                    )?.label
                  }
                </PageNavLink>
              );
            })}
            {bankingPageEnabled && (
              <PageNavLink
                to={`/i/${topPicksPage?.attributes.slug}/banking`}
                icon={<PiggyBank />}
              >
                Banking
              </PageNavLink>
            )}
            {businessBankingPageEnabled && (
              <PageNavLink
                to={`/i/${topPicksPage?.attributes.slug}/business-banking`}
                icon={<Library className="fill-theme-blue-darkest" />}
              >
                Business Banking
              </PageNavLink>
            )}
            <SearchBox
              submitIconComponent={() => <IoSearch />}
              classNames={{
                root: 'self-start',
                input:
                  'w-full pl-8 bg-theme-blue-darkest border-0 pt-0 pb-2 px-3 outline-none ring-0 focus:outline-none focus:ring-0 focus:border-b focus:border-b-white text-white placeholder:text-white focus:placeholder:text-slate-400 text-xl',
                submit: 'absolute left-0 top-1 flex h-full text-white text-2xl',
                form: 'relative flex w-full',
                reset: 'hidden',
                loadingIndicator: 'hidden',
                loadingIcon: 'hidden',
              }}
              placeholder="Search cards"
              searchAsYouType={false}
            />
          </div>
        </div>
        <div
          className={classNames(
            'flex flex-col gap-4 overflow-x-auto transition-all duration-300 ease-in-out sm:flex-row sm:gap-6',
            {
              'mt-6 h-[44px]': subNavOpen,
              'mt-0 h-0': !subNavOpen,
            }
          )}
        >
          {subNavItems?.map((item) => (
            <SubNavItem
              onClick={item.onClick}
              key={item.label}
              label={item.label}
            />
          ))}
        </div>
      </div>
      {results && results.nbHits > 0 && query && query !== '' && (
        <div className="flex justify-center">
          <Button className="mt-4" onClick={handleClearSearchResults} ghost>
            Clear search results
          </Button>
        </div>
      )}
    </>
  );
}
