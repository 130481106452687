import classNames from 'classnames';

type SubNavItemProps = {
  onClick?: () => void;
  label: string;
  className?: string;
};

export default function SubNavItem({
  onClick,
  label,
  className,
}: SubNavItemProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'flex items-center text-xl font-bold border-b-4 pb-[4px] sm:pb-[12px] text-white border-b-transparent hover:border-b-theme-cyan-light',
        className
      )}
    >
      <span className="whitespace-nowrap">{label}</span>
    </button>
  );
}
